<template>
  <Section v-if="!parcel || !parcel_sheet">
    <Container>
      <p>
        Vous n'avez pas ajouté de parcelle à l'essai.
      </p>
      <div class="grid">
        <div class="tmp-col">
          <Btn v-if="helperService.userHasPermission('parcelle_add')" text="Ajouter nouvelle parcelle"
            :to="{ name: 'parcelAdd', query: { from: $route.name, e: $route.params.id } }" icon="plus" color="default" />
        </div>
        <input type="hidden" name="void" />
      </div>

    </Container>
  </Section>
  <Parcel v-if="parcel && parcel_sheet" :parcel="parcel" :parcel_sheet="parcel_sheet" map />
</template>
<script>
import Parcel from '@/views/componentsViews/parcel/Parcel.vue'
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'

export default {
  name: 'ParcelSubview',
  components: {
    Parcel,
    Section,
    Container,
  },
  props: {
    pageTitle: {
      type: String,
    },
    essai: {
      type: Object,
    },
  },
  data() {
    return {
      parcel: this.essai?.parcelle,
      parcel_sheet: this.essai?.parcelle_fiche,
    }
  },
  mounted() { },
  methods: {},
}
</script>
