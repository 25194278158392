<template>
  <Modal :title="title" :active="modalActive" @modal-close="close" size="xl" no-scroll>
    <template v-slot:modal-body>
      <Loader :active="loading" />

      <p class="h4">Modalité N° {{ formValues?.ordre ? formValues.ordre : modalitiesNb + 1 }}</p>

      <!-- Type de modalité -->
      <Radio v-if="typemodalite && canEditModa" :compkey="compkey" :disabled="!editAll" required id="typemodalite"
        :items="typemodalite" label="Type de modalité" inputStyle="inline" v-model="formValues.type_uid" />

      <!-- Numéro local -->
      <Input :disabled="!editAll" id="numero" label="Numéro local" v-model="formValues.numero" />

      <!-- Destruction -->
      <Radio v-if="canEditModa" id="destruction" :disabled="!editAll" :compkey="compkey" :items="[
        { label: 'Oui', value: true },
        { label: 'Non', value: false },
      ]" label="Destruction de la modalité" inputStyle="inline" v-model="formValues.destruction" />

      <!-- Modalité optionnelle -->
      <Radio v-if="canEditModa" id="option" :compkey="compkey" :items="[
        { label: 'Oui', value: true },
        { label: 'Non', value: false },
      ]" label="Modalité optionnelle" inputStyle="inline" v-model="formValues.option" />

      <!-- Début des champs liés aux facteurs -->
      <template v-for="(header, index) in headers" :key="index">
        <!-- SEMENCES -->
        <template v-if="['SEMENCES', 'SANTEVEGETALE', 'FERTILISATION'].includes(header.type.uid)">

          <div class="title-block">
            <div class="h4 no-margin-bottom" v-if="dataLoaded">
              <div v-if="header?.designation">
                <span>{{ header?.designation }} - </span>
                <span class="color-gray-light">{{ header.type.designation }}</span>
              </div>
              <div v-else>
                <span>{{ header.type.designation }}</span>
              </div>
            </div>

            <div class="title-block-subtitle">
              <template v-for="passage in header.passages" v-bind:key="passage.id">
                <template v-if="passage.id == header.passage_id">
                  Stade d'application : {{ passage.stade?.code_bbch }} -
                  {{ passage.stade?.designation_court }}.
                </template>
              </template>
            </div>

            <ToolDropdown v-if="canEditModa" dropdown-align="left" toggle-btn-text="Ajouter un produit" class="tool-dropdown--alt">
              <MenuItem :item-data="{ label: 'Semence' }" @click="addProductLine(index, 'SEMENCES')" />
              <MenuItem :item-data="{ label: 'Santé végétale' }" @click="addProductLine(index, 'SANTEVEGETALE')" />
              <MenuItem :item-data="{ label: 'Fertilisant' }" @click="addProductLine(index, 'FERTILISATION')" />
            </ToolDropdown>
          </div>

          <div class="product-repeater" v-if="dataLoaded && formValues.factors[index]">
            <template v-for="(product, productIndex) in formValues.factors[index].products" :key="productIndex">

              <div :class="`product-repeater-line ${formValues.factors[index].products[productIndex].error}`">
                <div class="col">
                  <div class="product-repeater-line-title">
                    <template
                      v-if="formValues.factors[index].products[productIndex].type === 'SEMENCES'">Semence</template>
                    <template v-if="formValues.factors[index].products[productIndex].type === 'SANTEVEGETALE'">Santé
                      végétale</template>
                    <template
                      v-if="formValues.factors[index].products[productIndex].type === 'FERTILISATION'">Fertilisant</template>
                  </div>
                </div>

                <div class="col">
                  <SelectExtended :id="`formValues.factors[${index}].products[${productIndex}].product`"
                    :label="productIndex == 0 ? 'Produit' : null" option-key="id" option-value="designation"
                    special="Produit" :items="getFiltredProduct(formValues.factors[index].products[productIndex].type)"
                    @update:modelValue="getFirstSupplier($event, index, productIndex)"
                    :globalError="formValues.factors[index].products[productIndex].error"
                    v-model="formValues.factors[index].products[productIndex].product"
                    :disabled="!canEditModa"
                    />
                </div>

                <div class="col">
                  <Input :id="`formValues.factors[${index}].products[${productIndex}].traitement`"
                    :label="productIndex == 0 ? 'Traitement' : null"
                    v-model="formValues.factors[index].products[productIndex].traitement"
                    :disabled="formValues.factors[index].products[productIndex].type !== 'SEMENCES'" />
                </div>

                <div class="col">
                  <Input :id="`formValues.factors[${index}].products[${productIndex}].densitedose`"
                    :label="productIndex == 0 ? 'Densité / doses' : null"
                    v-model="formValues.factors[index].products[productIndex].densitedose" type="number"
                    :globalError="formValues.factors[index].products[productIndex].error"
                    :options="{ min: 0, step: 0.0001 }"
                    :disabled="!canEditModa"
                    />
                </div>

                <div class="col">
                  <SelectExtended :label="productIndex == 0 ? 'Unité' : null"
                    :id="`formValues.factors[${index}].products[${productIndex}].unite`" special="Unité"
                    :apiParams="{ sort: 'valeur.ASC' }" optionKey="uid" optionValue="valeur" apiEndpoint="unite"
                    :globalError="formValues.factors[index].products[productIndex].error"
                    :disabled="!canEditModa"
                    v-model="formValues.factors[index].products[productIndex].unite" />
                </div>

                <div class="col">
                  <Input :id="`formValues.factors[${index}].products[${productIndex}].pmg`"
                    :label="productIndex == 0 ? 'PMG' : null"
                    v-model="formValues.factors[index].products[productIndex].pmg"
                    :disabled="formValues.factors[index].products[productIndex].type !== 'SEMENCES'" />
                </div>

                <div class="col">
                  <SelectExtended :label="productIndex == 0 ? 'Fournisseur' : null"
                    :id="`formValues.factors[${index}].products[${productIndex}].fournisseur`" special="Fournisseur"
                    :apiParams="{ sort: 'nom.ASC', limit: 0 }" optionKey="id" optionValue="nom"
                    apiEndpoint="fournisseur" v-model="formValues.factors[index].products[productIndex].fournisseur"
                    :disabled="!canEditModa" />
                </div>

                <div class="col" v-if="canEditModa">
                  <Btn icon="trash-alt" title="Supprimer le produit" @click="deleteProductLine(index, productIndex)" />
                </div>
              </div>

            </template>

          </div>
        </template>

        <!-- TRAVAILDUSOL -->
        <template v-if="editAll && header.type.uid === 'TRAVAILDUSOL'">
          <div v-if="dataLoaded">
            <div class="h4 no-margin-bottom" v-if="dataLoaded">
              <div v-if="header?.designation">
                <span>{{ header?.designation }}</span>
                <span class="color-gray-light"> - {{ header.type.designation }}</span>
              </div>
              <div v-else>
                <span>{{ header.type.designation }}</span>
              </div>
            </div>

            <div class="margin-bottom-half color-gray-light">
              <template v-for="passage in header.passages" v-bind:key="passage.id">
                <template v-if="passage.id == header.passage_id">
                  Stade d'application : {{ passage.stade?.code_bbch }} -
                  {{ passage.stade?.designation_court }}.
                </template>
              </template>
            </div>
          </div>

          <template v-if="dataLoaded">
            <SelectExtended :id="`valeurstravaildusol[${index}].valeur`" label="Valeurs" option-key="id"
              option-value="valeur" :items="getFiltredProduct(header.type.uid)"
              v-model="formValues.factors[index].products" />
          </template>

        </template>

        <!-- PERSONNALISE -->
        <template v-if="editAll && header.type.uid === 'PERSONNALISE'">
          <p class="h4" v-html="`${header.type.designation}`"></p>
          <template v-if="dataLoaded">
            <!-- PERSONNALISE - CHAMP DE TYPE LISTE -->
            <!-- eslint-disable -->
            <SelectExtended v-if="header.variables[0].type.format_variable.uid === 'LISTE'"
              :id="`valeurspersonalise[${index}].valeur`" label="Valeurs" option-key="uid" option-value="valeur"
              :apiEndpoint="`dictionnaire/referentiel/${header.variables[0].type.referentiel.id}/valeur`"
              v-model="formValues.factors[index].personnalise.value" />

            <!-- PERSONNALISE - CHAMP DE TYPE TEXTE -->
            <Input v-if="header.variables[0].type.format_variable.uid === 'VARCHAR'"
              :id="`valeurspersonalise[${index}].valeur`" label="Valeur" value="red"
              v-model="formValues.factors[index].personnalise.value" />

            <!-- PERSONNALISE - CHAMP DE TYPE DATE -->
            <Input v-if="header.variables[0].type.format_variable.uid === 'DATE'"
              :id="`valeurspersonalise[${index}].valeur`" label="Date" type="date"
              v-model="formValues.factors[index].personnalise.value" />
          </template>
        </template>
      </template>


      <!-- Désignation auto -->
      <Radio id="designationauto" v-if="canEditModa" :compkey="compkey" :items="[
        { label: 'Automatique', value: true },
        { label: 'Manuelle', value: false },
      ]" label="Définition de désignation de la modalité" inputStyle="inline" v-model="formValues.designationauto" />


      <div v-show="formValues.designationauto === true || formValues.designationauto === 'true'">
        <p class="h4">Désignation</p>
        <KeyValue label="Désignation automatique de la modalité" :value="defaultDesignation" />
      </div>

      <!-- Désignation de la modalité -->
      <div v-show="formValues.designationauto === false || formValues.designationauto === 'false'">
        <p class="h4">Désignation</p>
        <Input :disabled="!canEditModa" id="designation" required label="Désignation de la modalité"
          v-model="formValues.designation" />
      </div>

    </template>
    <template v-slot:modal-footer>
      <Btn text="Annuler" @click="close" />
      <Btn v-if="!edit_modality" text="Ajouter" color="primary" @click="handleSubmit" />
      <Btn v-if="edit_modality" text="Enregistrer les modifications" @click="handleSubmit" color="primary" />
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/layout/Modal.vue'
import Btn from '@/components/base/Btn.vue'
import SelectExtended from '@/components/form/SelectExtended.vue'
import Input from '@/components/form/Input.vue'
import Radio from '@/components/form/Radio.vue'
import Loader from '@/components/layout/Loader.vue'
import KeyValue from '@/components/form/KeyValue.vue'
import ToolDropdown from '@/components/layout/ToolDropdown.vue'
import MenuItem from '@/components/layout/MenuItem.vue'

export default {
  name: 'AddEditModaliteModal',
  components: {
    SelectExtended,
    Modal,
    Btn,
    Input,
    KeyValue,
    Radio,
    Loader,
    MenuItem,
    ToolDropdown,
  },
  props: {
    protocol_id: {
      type: [Number, String],
    },
    modalActive: {
      type: Boolean,
    },
    headers: {
      type: Object,
    },
    modalities: {
      type: Object,
    },
    produits: {
      type: Object,
    },
    modalitiesNb: {
      type: [Number, String],
    },
    editAll: {
      type: Boolean,
      default: true,
    },
    modality_id: {
      type: [Number, String],
      default: null,
    },
    edit_modality: {
      type: Boolean,
      default: false,
    },
    compkey: {
      type: String,
    },
  },
  data() {
    return {
      typemodalite: null,
      formValues: {
        type_uid: 'PROTOCOLE',
        numero: null,
        destruction: false,
        option: false,
        designationauto: 'true',
        designation: null,
        factors: [],
      },
      defaultProduct: {
        id: null,
        type: null,
        product: null,
        traitement: null,
        densitedose: null,
        pmg: null,
        unite: null,
        fournisseur: null,
        error: false,
      },
      title: null,
      modality: null,
      canEditModa: true,
      loading: false,
      saveInitValues: null,
      defaultDesignation: null,
      emptyValues: null,
      modalityId: null,
      errors: null,
      dataLoaded: false,
    }
  },
  emits: ['modal-close'],
  watch: {
    modalActive() {
      this.resetData()
      this.setData()
    },
    modality_id() {
      this.resetData()
      this.setData()
    },
    formValues: {
      handler() {
        this.updateDefaultDesignation()
      },
      deep: true,
    },
  },
  mounted() {
    this.title = 'Ajouter une modalité'
    this.modalityId = this.modality_id ? this.modality_id : null
    if (this.edit_modality) {
      this.title = 'Modifier une modalité'
    }
    this.setData()
  },
  methods: {
    async setData() {
      this.dataLoaded = false
      this.loading = true

      if (this.$store.state.app.typeModalite) {
        this.typemodalite = this.$store.state.app.typeModalite.map((type) => ({
          label: type.designation,
          value: type.uid,
        }))
      } else {
        const typeModaliteResponse = await this.fetchService.get('dictionnaire/type/modalite', { limit: 0 })
        this.typemodalite = typeModaliteResponse.data.map((type) => ({
          label: type.designation,
          value: type.uid,
        }))
      }

      const factors = []
      let factorIndex = 0

      // eslint-disable-next-line no-restricted-syntax
      for (const header of this.headers) {
        let factor = {
          id: header.id, type: header.type.uid, products: [],
        }

        if (factorIndex > 0) {
          header.before_factor_id = this.headers[factorIndex - 1].id
        }

        if (header.passage_id) {
          factor = { ...factor, ...{ passage_id: header.passage_id } }
        }

        if (header.variable_id) {
          factor = { ...factor, ...{ variable_id: header.variable_id, personnalise: { value: null, type: header.variables[0].type.format_variable.uid } } }
        }

        factors.push(factor)
        factorIndex += 1
      }

      this.formValues.factors = factors
      this.emptyValues = this.helperService.cloneObject(this.formValues)

      if (this.edit_modality) {
        await this.loadData()
      }

      this.saveInitValues = this.helperService.cloneObject(this.formValues)

      this.updateDefaultDesignation()

      this.loading = false
      this.dataLoaded = true
    },

    async loadData() {
      const reponseModalityResponse = await this.fetchService.get(`protocole/${this.protocol_id}/modalite/${this.modality_id}`)

      this.modality = reponseModalityResponse.data
      this.canEditModa = this.modality?.dat_modalite_id === null

      this.formValues.type_uid = this.modality.type.uid
      this.formValues.numero = this.modality.numero
      this.formValues.destruction = this.modality.destruction
      this.formValues.option = this.modality.option
      this.formValues.designationauto = this.modality.designationauto
      this.formValues.designation = this.modality.designation ?? ''

      let factorIndex = 0
      // eslint-disable-next-line no-restricted-syntax
      for (const factor of this.formValues.factors) {
        let localProduct = []
        if (['SEMENCES', 'SANTEVEGETALE', 'FERTILISATION'].includes(factor.type)) {
          // eslint-disable-next-line no-await-in-loop
          const reponseProduitResponse = await this.fetchService.get(`protocole/${this.protocol_id}/facteur/${factor.id}/passage/produit`, { limit: 0, order: 'id.desc', filters: `modalite.id:eq(${this.modality_id})` })

          // eslint-disable-next-line no-await-in-loop
          const reponseSemenceResponse = await this.fetchService.get(`protocole/${this.protocol_id}/modalite/${this.modality_id}/semence`, { limit: 0, order: 'id.desc' })

          if (reponseSemenceResponse.data.length > 0) {
            localProduct = localProduct.concat(reponseSemenceResponse.data.filter((prod) => prod.facteur.id === factor.id).sort((a, b) => a.id - b.id))
          }

          if (reponseProduitResponse.data.length > 0) {
            localProduct = localProduct.concat(reponseProduitResponse.data.sort((a, b) => a.id - b.id))
          }

          if (factor?.passage_id) {
            localProduct = localProduct.filter((prod) => prod.dat_passage_id === factor.passage_id)
          }

          localProduct = localProduct.filter((prod) => prod.modalite.id === this.modality_id)

          this.formValues.factors[factorIndex].products = this.formatProductsForVmodel(localProduct)
        }

        if (['TRAVAILDUSOL'].includes(factor.type)) {
          // eslint-disable-next-line no-await-in-loop
          const reponseTsResponse = await this.fetchService.get(`protocole/${this.protocol_id}/facteur/${factor.id}/passage/${factor.passage_id}/travail_du_sol`)
          if (reponseTsResponse.data.length > 0) {
            let localValue = reponseTsResponse.data
            localValue = localValue.filter((prod) => prod.modalite.id === this.modality_id).shift()

            if (localValue) {
              this.formValues.factors[factorIndex].products = { key: localValue.sol_travail.id, value: localValue.sol_travail.valeur }
              this.formValues.factors[factorIndex].travail_id = localValue.id
            }
          }
        }

        if (['PERSONNALISE'].includes(factor.type)) {
          // eslint-disable-next-line no-await-in-loop
          const reponseVResponse = await this.fetchService.get(`protocole/${this.protocol_id}/facteur/${factor.id}/variable/${factor.variable_id}/valeur`,
            {
              filters: `modalite.id:eq(${this.modality_id})`,
            })
          const valeur = reponseVResponse.data.shift()
          this.formValues.factors[factorIndex].personnalise.value = valeur?.valeur
          this.formValues.factors[factorIndex].personnalise.id = valeur?.id
        }

        factorIndex += 1
      }
    },
    resetData() {
      this.dataLoaded = false
      this.initValues = null
      this.modalityId = null
      this.formValues = {
        type_uid: 'PROTOCOLE',
        numero: null,
        destruction: false,
        option: false,
        designationauto: true,
        designation: null,
        factors: [],
      }
    },
    close() {
      this.resetData()
      this.$emit('modal-close', this.activeModal)
    },
    addProductLine(index, type) {
      const localProduct = this.helperService.cloneObject(this.defaultProduct)
      localProduct.type = type

      this.formValues.factors[index].products.push(localProduct)
    },
    deleteProductLine(factorIndex, productIndex) {
      this.formValues.factors[factorIndex].products.splice(productIndex, 1)
    },
    async handleSubmit() {
      this.loading = true

      if (this.checkProducts()) {
        this.formValues.destruction = this.formValues.destruction === 'true'
        this.formValues.option = this.formValues.option === 'true'
        console.log(' this.formValues', this.formValues)

        let modaliteResponse = null
        if (this.modality_id) {
          modaliteResponse = await this.fetchService.put(`protocole/${this.protocol_id}/modalite/${this.modality_id}`, this.formValues)
        } else {
          modaliteResponse = await this.fetchService.post(`protocole/${this.protocol_id}/modalite`, this.formValues)
        }

        if (modaliteResponse.meta.success) {
          this.modalityId = modaliteResponse.data.id
          let index = 0
          const promiseArray = []
          const deletePromiseArray = []

          // eslint-disable-next-line
          for (const factor of this.formValues.factors) {

            const initProducts = this.saveInitValues.factors[index].products

            if (['SEMENCES', 'SANTEVEGETALE', 'FERTILISATION'].includes(factor.type)) {
              const toUpdate = factor.products.filter((x) => initProducts.find((item) => item.id === x.id))
              const toDelete = initProducts.filter((x) => !factor.products.find((item) => item.id === x.id))
              const toAdd = factor.products.filter((x) => !initProducts.find((item) => item.id === x.id))

              if (toDelete.length > 0) {
                toDelete.forEach((Dproduit) => {
                  if (Dproduit.type === 'SEMENCES') {
                    deletePromiseArray.push(this.fetchService.delete(`protocole/${this.protocol_id}/modalite/${this.modalityId}/semence`,
                      [{ id: Dproduit.id }]))
                  } else {
                    deletePromiseArray.push(this.fetchService.delete(`protocole/${this.protocol_id}/facteur/${factor.id}/passage/produit`,
                      [{ id: Dproduit.id }]))
                  }
                })
              }
              if (toUpdate.length > 0) {
                toUpdate.forEach((produit) => {
                  if (produit.type === 'SEMENCES') {
                    promiseArray.push(this.fetchService.put(`protocole/${this.protocol_id}/modalite/${this.modalityId}/semence`,
                      this.formatProducts([produit], factor)))
                  } else {
                    promiseArray.push(this.fetchService.put(`protocole/${this.protocol_id}/facteur/${factor.id}/passage/produit`,
                      this.formatProducts([produit], factor)))
                  }
                })
              }

              if (toAdd.length > 0) {
                // eslint-disable-next-line
                for (const produit of toAdd) {
                  if (produit.type === 'SEMENCES') {
                    // eslint-disable-next-line
                    await this.fetchService.post(`protocole/${this.protocol_id}/modalite/${this.modalityId}/semence`,
                      this.formatProducts([produit], factor))
                  } else {
                    // eslint-disable-next-line
                    await this.fetchService.post(`protocole/${this.protocol_id}/facteur/${factor.id}/passage/produit`,
                      this.formatProducts([produit], factor))
                  }
                }
              }
            }

            if (['TRAVAILDUSOL'].includes(factor.type)) {
              if (factor.travail_id) {
                // eslint-disable-next-line
                await this.fetchService.delete(`protocole/${this.protocol_id}/facteur/${factor.id}/passage/${factor.passage_id}/travail_du_sol`,
                  [{ id: factor.travail_id }])
              }

              if (!Array.isArray(factor.products) && factor?.products?.key) {
                promiseArray.push(this.fetchService.post(`protocole/${this.protocol_id}/facteur/${factor.id}/passage/${factor.passage_id}/travail_du_sol`,
                  [{ modalite_id: this.modalityId, sol_travail_id: factor.products.key }]))
              }
            }

            if (['PERSONNALISE'].includes(factor.type)) {
              if (factor?.personnalise?.id) {
                // eslint-disable-next-line
                await this.fetchService.delete(`protocole/${this.protocol_id}/facteur/${factor.id}/variable/${factor.variable_id}/valeur/${factor.personnalise.id}`)
              }

              let valeurModa = factor.personnalise.value
              if (typeof factor.personnalise.value === 'object') {
                valeurModa = factor.personnalise?.value?.key
              }

              if (valeurModa) {
                promiseArray.push(this.fetchService.post(`protocole/${this.protocol_id}/facteur/${factor.id}/variable/${factor.variable_id}/valeur`,
                  { modalite_id: this.modalityId, valeur: valeurModa }))
              }
            }

            index += 1
          }

          await Promise.all(deletePromiseArray)
          await Promise.all(promiseArray)

          this.emitter.emit('alert', {
            type: 'success',
            content: 'La modalité a bien été mise à jour.',
          })
          this.emitter.emit('refresh-modalities-table')
          this.close()
          this.loading = false
        }
      } else {
        this.loading = false
      }
    },
    formatProductsForVmodel(products) {
      return products.map((product) => {
        const localProduct = this.helperService.cloneObject(this.defaultProduct)
        localProduct.id = product.id

        if (product?.produit?.id) {
          localProduct.product = { key: product.produit.id, value: product.produit.designation }
        } else {
          localProduct.product = null
        }

        if (product?.fournisseur?.id) {
          localProduct.fournisseur = { key: product.fournisseur.id, value: product.fournisseur.nom }
        } else {
          localProduct.fournisseur = null
        }

        if (product?.unite?.id) {
          localProduct.unite = { key: product?.unite?.uid, value: product?.unite?.valeur }
        } else {
          localProduct.unite = null
        }

        localProduct.traitement = product?.traitementsemence
        localProduct.pmg = product?.pmg
        localProduct.type = (product?.produit?.type?.uid === 'SEMENCE') ? 'SEMENCES' : product?.produit?.type?.uid
        localProduct.type = (localProduct.type === 'FERTILISANT') ? 'FERTILISATION' : localProduct.type
        localProduct.type = localProduct.type ? localProduct.type : 'SEMENCES'

        localProduct.densitedose = localProduct.type === 'SEMENCES' ? product?.densite : product?.dose
        return localProduct
      })
    },
    formatProducts(products, factor) {
      return products.map((product) => {
        const prod = {
          id: product?.id,
          passage_id: factor?.passage_id,
          facteur_id: factor?.id,
          modalite_id: this.modalityId,
          dose: product?.densitedose,
          densite: product?.densitedose,
          fournisseur_id: product?.fournisseur?.key,
          produit_id: product?.product?.key,
          unite_uid: product?.unite?.key,
          traitementsemence: product?.traitement,
          pmg: product?.pmg,
        }
        if (prod.id === null) {
          delete prod.id
        }
        return prod
      })
    },
    async getFirstSupplier(product, index, productIndex) {
      if (product?.key) {
        const responseProduit = await this.fetchService.get(`produit/${product?.key}`)
        if (responseProduit.data?.noms_commerciaux.length > 0) {
          const supplier = responseProduit.data?.noms_commerciaux[0]
          this.formValues.factors[index].products[productIndex].fournisseur = { key: supplier?.fournisseur?.id, value: supplier?.fournisseur?.nom }
        } else {
          this.formValues.factors[index].products[productIndex].fournisseur = null
        }
      } else if (this.formValues.factors.length > 0 && productIndex) {
        this.formValues.factors[index].products[productIndex].fournisseur = null
      }
    },

    getFiltredProduct(type) {
      const items = this.produits[type].map((d) => ({
        id: d.id,
        designation: d?.designation,
        valeur: d?.valeur,
      }))
      return items
    },

    checkProducts() {
      let error = false
      this.formValues.factors.forEach((factor, index) => {
        if (['SEMENCES', 'SANTEVEGETALE', 'FERTILISATION'].includes(factor.type)) {
          factor.products.forEach((product, indexp) => {
            if (['SANTEVEGETALE', 'FERTILISATION'].includes(product.type)) {
              this.formValues.factors[index].products[indexp].error = false

              if (product?.densitedose === null || product?.densitedose === '') {
                this.formValues.factors[index].products[indexp].error = true
                error = true
              }

              if (!product?.unite) {
                this.formValues.factors[index].products[indexp].error = true
                error = true
              }

              if (!product?.product?.key) {
                this.formValues.factors[index].products[indexp].error = true
                error = true
              }
            }
          })
        }
      })

      if (error) {
        this.emitter.emit('alert', {
          type: 'error',
          content: 'Un produit est mal renseigné',
        })
      }

      return !error
    },

    updateDefaultDesignation() {
      this.defaultDesignation = null
      const designationValues = []

      this.formValues.factors.forEach((factor) => {
        let localDesignation = []

        if (['SEMENCES', 'SANTEVEGETALE', 'FERTILISATION'].includes(factor.type)) {
          if (factor.products.length > 0) {
            localDesignation = factor.products.map((product) => {
              let label = product?.product?.value ? product?.product?.value : '-'
              if (product?.densitedose) {
                label = `${label} ${product.densitedose}`
              }
              return label
            }).join(' + ')
          } else {
            localDesignation = '-'
          }
        }
        if (['TRAVAILDUSOL'].includes(factor.type)) {
          if (factor?.products?.value) {
            localDesignation = factor?.products?.value
          } else {
            localDesignation = '-'
          }
        }
        if (['PERSONNALISE'].includes(factor.type)) {
          if (factor?.personnalise?.value) {
            localDesignation = factor?.personnalise.value
            if (factor?.personnalise?.type === 'DATE') {
              localDesignation = this.helperService.displayDate(localDesignation)
            }
          } else {
            localDesignation = '-'
          }
        }

        designationValues.push(localDesignation)
      })

      this.defaultDesignation = designationValues.join(' / ')
      if (this.formValues.designationauto) {
        this.formValues.designation = this.defaultDesignation
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.no-margin-bottom {
  margin-bottom: 0 !important;
}

.title-block {
  display: flex;
  align-items: center;
  gap: $gutter-quarter;
  margin-bottom: $gutter;
}

.title-block-subtitle {
  color: $color-gray-light;
  // text-transform: uppercase;
  font-size: $font-size-big;
  font-weight: $font-weight-semibold;

  &::before {
    content: "-";
    display: inline-block;
    margin-right: $gutter-eighth;
  }
}

.tool-dropdown--alt {
  margin-left: $gutter-quarter;
}

.product-repeater {
  margin-bottom: $gutter-double;
  display: table;
  width: 100%;
}

.product-repeater-line {
  display: table-row;

  >.col {
    display: table-cell;
    padding: $gutter-eighth;
    vertical-align: bottom;

    &:not(:first-child):not(:last-child) {
      width: calc(100% / 7);
    }
  }
}

.product-repeater-line-title {
  line-height: 4.4rem;
}
</style>
